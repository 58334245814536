import React, { FC, useContext } from 'react';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { HighlightedServicesType } from '../../../models/highlighted-services-type';
import { InformationBlockType } from '../../../models/information-block-type';
import InformationBlockGroup from '../../molecules/information-block-group/information-block-group';

const HighlightedServices: FC<HighlightedServicesType> = ({
  heading,
  callToActions,
}: HighlightedServicesType) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  return (
    <div className="py-component-spacing-mobile lg:py-component-spacing">
      {heading && (
        <h2 className="page-heading-three mb-12 mx-auto text-center md:w-8/12 lg:w-6/12">
          {heading}
        </h2>
      )}

      <InformationBlockGroup
        blocks={callToActions}
        onSelect={(block: InformationBlockType) => {
          pushToDataLayer(GtmEventNameEnum.CardUsp, {
            title: block.heading,
          });
        }}
      />
    </div>
  );
};

export default HighlightedServices;
